import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header2 from '../../parts/header2';
import Side from '../../parts/side';

// Images


const InfoTermsPage = (props) => {
  const { i18n, t } = useTranslation();

  return(
    <>
      <section id="home">
          <Header2 props={props} />
      </section>

      <section id="content" className="border-top">
          <div className="container">
              <h2 className="headline-left">Všeobecné zmluvné podmienky a ustanovenia pri preprave zásielok (VZP)</h2>
              <div className="row">

                  <div className="col-xl-8 main">
                    <section id="we-transport">
          						<h3>Článok č. 1</h3>
          							<p>
          								Tieto VZP platia pre obchodné vzťahy objednávateľov a prepravcu (spoločnosti Maťko a Kubko).
          								Objednávateľ na základe objednávky vyslovuje súhlas s ich znením uverejneným na stránke <a href="/">https://www.matkoakubko.com/</a> a
          								rešpektuje, že tieto majú prednosť pred znením Obchodného zákonníka a Občianskeho zákonníka (skratka OZ), nesmú však byť
          								v rozpore s ich kogentnými ustanoveniami ani s donucujúcimi ustanoveniami iných právnych predpisov.
                        </p>
          							<p>
          								Tieto VZP sú záväzné od momentu prevezatia zásielky prepravcom, pokiaľ nie je inak písomne dohodnuté s oprávneným zástupcom prepravcu..
          								Na účely týchto VZP sa „Zásielkou” rozumejú všetky balíky a príslušná dokumentácia, ktoré sa prepravujú v rámci jedného nákladového listu
          								a ktoré môžu byť prepravované akýmkoľvek spôsobom.
                        </p>
          							<p>
          								Každá Zásielka bude dopravovaná za podmienok uvedených v týchto VZP. V prípade, že Odosielateľ požaduje zabezpečenie Zásielky vo väčšej
          								miere než je to uvedené, na základe vzájomnej dohody môže dôjsť k poisteniu zásielky za úhradu.
                        </p>
          						<h3>Článok č. 2</h3>
          							<p>
          								Objednávateľ súhlasí s tým, že jeho Zásielka je vhodná na prepravu a za neakceptovateľnú bude považovaná, ak:
                        </p>
          							<ul style={{ paddingLeft: "20px" }}>
          								<li>
          									bude klasifikovaná ako nebezpečný materiál, nebezpečný tovar, zakázané veci alebo veci, ktorých preprava je obmedzená zo strany IATA
          									(InternationalAir Transport Association), CAO (International Civil AviationOrganisation), ADR (EuropeanRoad Transport Regulation on dangerousgoods)
          									ktoréhokoľvek kompetentného vládneho úradu alebo inej príslušnej organizácie
                          </li>
          								<li>
          									obsahuje falšovaný tovar, zvieratá, vzácne kovy, peniaze, banderolu / nálepku o zaplatení dane, obchodovateľné dokumenty na doručiteľa, drahé kovy a kamene,
          									skutočné strelné zbrane alebo ich imitácie, časti zbrane, zbrane, výbušniny a muníciu, ľudské pozostatky, pornografia a ilegálne narkotiká / lieky, alkohol,
          									tabak a tabakové výrobky
                          </li>
          								<li>
          									obsahuje akékoľvek iné veci, o ktorých prepravca rozhodne, že nemôžu byť prepravené bezpečne alebo legálne (napr. motorky) alebo jej obal je poškodený alebo
          									neprimeraný (napr. elektroniku musí byť zabalená v krabici)
                          </li>
          								<li>
          									Zásielku nemožno doručiť do poštových schránok alebo iba na poštové smerovacie číslo
                          </li>
						  				<li>
										  Od 1. 2. 2021 neprepravujeme akékoľvek televízory (ani používané) a žiadnu novú elektrotechniku. Zásielka nesmie obsahovať mäsové a mliečne výrobky
										</li>
          							</ul>
          							<p>
          								Zásielka sa doručuje na adresu Príjemcu, ktorú oznámi objednávateľ (za ktorého sa v prípade poštových služieb bude považovať prvý poštový úrad, ktorý Zásielku prijme),
          								ale nie bezpodmienečne menovanému Príjemcovi osobne. Zásielky adresované na adresu s centrálnym príjmom Zásielok budú doručené do centrálneho príjmu. V prípade, ak Príjemca
          								Zásielku odmietne alebo odmietne zaplatiť za doručenie alebo Zásielka bude považovaná za neakceptovateľnú alebo bola alebo Príjemcu nie je možné identifikovať alebo nájsť
          								prepravca vynaloží primerané úsilie na vrátenie Zásielky objednávateľovi na jeho náklady, pričom v prípade, že to nebude možné, môže byť táto Zásielka uvoľnená, môže sa s
          								ňou voľne nakladať alebo ju prepravca môže predať bez toho, aby znášal akúkoľvek zodpovednosť voči objednávateľovi alebo komukoľvek inému, pričom takto získaný výťažok sa
          								použije na pokrytie nákladov za služby a súvisiace administratívne náklady a zvyšok výťažku z predaja sa vráti objednávateľovi.
                        </p>
          						<h3>Článok č. 3</h3>
          							<p>
          								Prepravca má právo otvoriť a skontrolovať Zásielku bez predchádzajúceho súhlasu objednávateľa tak, aby nedošlo k jej poškodeniu.
                        </p>
          						<h3>Článok č. 4</h3>
          							<p>
          								Ceny prepravcu za poskytované služby sú stanovené na základe skutočnej alebo objemovej hmotnosti podľa toho, ktorá z nich je
          								vyššia, a ktorákoľvek Zásielka môže prepravcom prevážená alebo premeraná za účelom potvrdenia tejto kalkulácie. Objednávateľ zaplatí
          								alebo nahradí prepravcovi všetky poplatky za odoslanie Zásielky, náklady na skladovanie, clá, dane, ktoré objednávateľ dlhuje prepravcovi
          								za poskytnuté služby alebo ktoré prepravca vynaložil v mene objednávateľa, Príjemcu alebo v mene akejkoľvek tretej strany, ako aj všetky nároky,
          								škody, pokuty a výdavky vynaložené prepravcom v prípade, že je Zásielka považovaná za neakceptovateľnú na prepravu.
                        </p>
          						<h3>Článok č. 5</h3>
          							<p>
          								Prepravca uzatvára zmluvu s Objednávateľom za tých podmienok, že zodpovednosť prepravcu je prísne obmedzená iba na priamu škodu a na limity podľa kg/lb.
          								Akékoľvek iné druhy strát alebo škôd sú vylúčené (vrátane, ale nielen, ušlého zisku, príjmu, úrokov, budúceho podnikania), či už je takáto strata alebo
          								škoda osobitná alebo nepriama, a to aj v prípade, že o riziku takejto straty alebo škody bol prepravca upovedomený pred prijatím alebo po prijatí Zásielky,
          								vzhľadom na to, že voči osobitným rizikám sa môže objednávateľ poistiť. V prípade, ak preprava Zásielky pozostáva z prepravy cestnej alebo inej, predpokladá sa,
          								že akákoľvek strata alebo škoda, ktorá vznikne, sa bude považovať za stratu alebo škodu, ktorá vznikla počas cestnej prepravy, pokiaľ sa nepreukáže inak.
          								Zodpovednosť prepravcu v súvislosti s akoukoľvek prepravovanou Zásielkou sa obmedzuje do výšky skutočnej hotovostnej hodnoty Zásielky a neprevýši 12,00 USD/kilogram
          								alebo 5,44 USD/lb na Zásielku prepravovanú cestnou dopravou.
                        </p>
          							<p>
          								Nároky sú obmedzené na jeden nárok za vysporiadanie jednej Zásielky, ktorého vysporiadanie sa považuje za úplné a konečné vysporiadanie všetkých strát a škôd s ním súvisiacich.
          								Ak Odosielateľ považuje tieto limity za nedostatočné, je povinný urobiť osobitné vyhlásenie o hodnote a požadovať poistenie, alebo si zabezpečiť vlastné poistenie, pri nesplnení
          								čoho Odosielateľ nesie všetky riziká straty alebo škody.
                        </p>
          							<p>
          								Všetky reklamácie sa musia prepravcovi predložiť písomne do 24 hodín odo dňa, keď Zásielku prepravca prijal, v opačnom prípade prepravca nepreberá žiadnu zodpovednosť.
                        </p>
          							<p>
          								Prepravca vynaloží všetko úsilie na doručenie Zásielky v súlade s bežnými dodacími lehotami prepravcu. Tieto dodacie lehoty však nie sú zaručené a nie sú súčasťou tejto zmluvy.
          								Prepravca nenesie žiadnu zodpovednosť za škody alebo straty spôsobené oneskoreným doručením.
                        </p>
          							<p>
          								Prepravca nenesie zodpovednosť za straty alebo škody spôsobené okolnosťami, ktoré nedokáže ovplyvňovať. Medzi takéto okolnosti patria najmä, ale nielen: „Živelné pohromy“ –
          								napríklad zemetrasenie, cyklón, búrka, povodeň, hmla; „Prípady vyššej moci“ – napríklad vojna, havária lietadla alebo embargo; akákoľvek chyba alebo vlastnosť týkajúca sa druhu
          								Zásielky, a to aj v prípade, že o nej prepravca vedel; demonštrácie alebo občianske nepokoje; akýkoľvek úkon alebo opomenutie akejkoľvek osoby, ktorá nie je zamestnancom alebo
          								zmluvným partnerom prepravcu, napríklad Objednávateľa, Príjemcu, tretej osoby, colných úradov alebo iných vládnych orgánov; protestnú akciu zamestnancov; elektrické alebo magnetické
          								poškodenie alebo vymazanie elektronických alebo fotografických záznamov, dát alebo nahrávok; ak je zásielka vyslovene nevhodne zabalená (napr. elektronika musí byť zabalená v krabici).
                        </p>
          							<p>
          								Objednávateľ prepravcu odškodní a zbavuje zodpovednosti za akúkoľvek stratu alebo škodu, ktorá vznikne z dôvodu, že Objednávateľ nedodržal ustanovenia aplikovateľného práva alebo
          								predpisov, a z dôvodu, že Objednávateľ porušil niektoré z nasledovných prehlásení a záruk:
                        </p>
          							<ul style={{ paddingLeft: "20px" }}>
          								<li>
          									Všetky informácie, ktoré poskytol Objednávateľ alebo jeho zástupcovia, sú správne a úplné
                          </li>
          								<li>
          									Zásielka bola pripravovaná v bezpečných priestoroch zamestnancami Objednávateľa
                          </li>
          								<li>
          									Objednávateľ zamestnáva zodpovedných pracovníkov na prípravu Zásielky
                          </li>
          								<li>
          									Objednávateľ zabezpečil Zásielku voči tomu, aby do nej zasahovala neoprávnená osoba počas jej prípravy, skladovania alebo prepravy do sídla prepravcu
                          </li>
          								<li>
          									Zásielka je riadne označená, je na nej uvedená adresa a je zabalená tak, aby bola zabezpečená preprava za predpokladu bežnej starostlivosti pri manipulácii so Zásielkou
                          </li>
          								<li>
          									Boli dodržané všetky príslušné platné colné, dovozné, vývozné a ostatné predpisy a nariadenia
                          </li>
          								<li>
          									Nákladový list bol podpísaný oprávneným zástupcom Objednávateľa a Podmienky a ustanovenia predstavujú záväzné a vynútiteľné záväzky Objednávateľa
                          </li>
          							</ul>
						<p>Objednávateľ súhlasí s každým smerovaním Zásielky a prípadnými obchádzkami, vrátane možnosti prepravy Zásielky prostredníctvom medzizastávok.</p>

						<h3>Článok č. 6</h3>
						<p>Poplatok za neskoré zrušenie objednávky je £20/20€ (objednávku je možné zrušiť bez poplatku najneskôr do 12 hodín po obdržaní potvdzujúcej sms s informáciou o vyzdvihnutí).</p>

						<h3>Článok č. 7</h3>
						<p>Akýkoľvek spor vyplývajúci z týchto Podmienok a ustanovení alebo s nimi akokoľvek súvisiaci bude podliehať Slovenskému právnemu poriadku</p>
					</section>
                  </div>

                  <div className="col-xl-4 side">
                      <Side />
                  </div>

              </div>
          </div>
      </section>
    </>
  );
}

export default InfoTermsPage;
