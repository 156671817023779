import React from 'react';
import { withTranslation } from 'react-i18next';

import Header2 from '../parts/header2';

import ContactForm from '../forms/contactForm';

// Assets


class ContactPage extends React.Component {
  render () {
    const { t } = this.props;

    return(
      <React.Fragment>
        <section id="home">
            <Header2 props={this.props} />
        </section>

        <div className="contact-form">
            <div className="container">
                <h2 className="headline-left">{t('contact')}</h2>
                <div className="row">

                    <div className="col-lg-6 form">
                        <ContactForm />
                    </div>

                    <div className="col-lg-6 contact-info">
                        <ul>
                            <li className="title">Matko a Kubko Logistics LTD</li>
                            <li>Unit 3 Navigation Point</li>
                            <li>Golds Hill Way</li>
                            <li>Tipton DY4 0PY</li>
                            <li>WEST MIDLANDS</li>
                            <li>VAT no: GB 175252506</li>
                        </ul>
                        <ul>
                            <li className="NatWest bank"></li>
                            <li>Miroslav Fedor</li>
                            <li><b>Account:</b> 6172 8829</li>
                            <li><b>SortCode:</b> 60 21 57</li>
                            <li><b>Branch address:</b></li>
                            <li>217 Dean Street</li>
                            <li>The Telford Centre</li>
                            <li>Telford TF3 4BB</li>
                        </ul>
                        <ul>
                            <li className="title">E-mail:</li>
                            <li><a href="mailto:matkoakubko@matkoakubko.com">matkoakubko@matkoakubko.com</a></li>
                        </ul>
                        <ul>
                            <li className="title">{t('phonenumber')}:</li>
                            <li>00421 949 105 003</li>
                            <li>0044 789 400 76 79</li>
                        </ul>
                        <ul>
                            <li className="title">{t('openinghours')}:</li>
                            <li><b>{t('mon')} - {t('fri')}:</b> 9:00 - 17:00</li>
                            <li><b>{t('sat')} - {t('sun')}:</b> Kontaktujte nás cez kontaktný formulár</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ContactPage);
